import React from "react"
import Nav from "../components/nav"
import Content from "../components/content"
import Card from "../components/card"

import oscon from "./img/oscon.jpg"
import sd2012 from "./img/scala-days-2012.jpg"
import sd2013 from "./img/scala-days-2013.jpg"
import sd2014 from "./img/scala-days-2014.jpg"
import sd2015 from "./img/scala-days-2015.jpg"
import sd2016 from "./img/scala-days-2016.jpg"
import sd2017 from "./img/scala-days-2017.jpg"
import sd2018 from "./img/scaladays-2018.jpg"

import sbtb2015 from "./img/sbtb-2015.jpg"
import conwaysBase from "./img/conways-base.jpg"
import scalabay from "./img/scalabay.jpg"

import svcc2013 from "./img/svcc-2013.jpg"
import svcc2014 from "./img/svcc-2014.jpg"
import svccKids2015 from "./img/svcc-kids-day-2015.jpg"

import javaoneKids from "./img/javaone-kids-day-2015.jpg"

import graphqlSummit from "./img/graphql-summit.jpg";
import sfScala from "./img/sfscala.jpg";
import sfScalaInterview from "./img/sfscala-interview.jpg";
import corecursive from "./img/corecursive.png";

import gpce from "./img/gpce.png"
import scalaPySBTB from "./img/scalapy-sbtb.png"
import scalapyScalaCon from "./img/scalapy-scalacon.jpg"
import { PageTitle } from "../components/titles"
import styled from "styled-components"

const talks = {
  2021: [
    {
      image: scalapyScalaCon,
      name: "Data Science in Scala with ScalaPy",
      conference: "ScalaCon",
      location: "Virtual"
    }
  ],
  2020: [
    {
      image: corecursive,
      name: "React and Scala.js with Shadaj Laddad",
      conference: "CoRecursive: The Stories Behind the Code",
      location: "Podcast",
      url: "https://corecursive.com/044-shadaj-laddad-react-and-scala-js"
    },
    {
      image: scalaPySBTB,
      name: "Data Science in Scala with ScalaPy",
      conference: "Scale By the Bay",
      location: "Virtual",
      url: "https://www.youtube.com/watch?v=hnlE9JQERjI"
    },
    {
      image: gpce,
      name: "Fluid Quotes",
      conference: "GPCE @ SPLASH",
      location: "Virtual",
      url: "https://www.youtube.com/watch?v=6LLW9WfFRMs"
    }
  ],
  2018: [
    {
      image: graphqlSummit,
      name: "Introducing Apollo VSCode",
      conference: "GraphQL Summit",
      location: "San Francisco",
      url: "https://www.youtube.com/watch?v=cZtQgK83AqQ"
    },
    {
      image: sfScala,
      name: "Slinky: a modern framework for modern apps",
      conference: "SF Scala",
      location: "Oakland",
      url: "https://www.youtube.com/watch?v=AkMVfy_86HY"
    },
    {
      image: sfScalaInterview,
      name: "Interview: 30 Years of Scala",
      conference: "SF Scala",
      location: "Oakland",
      url: "https://www.youtube.com/watch?v=1H4JvjmGC-Y"
    },
    {
      image: sd2018,
      name: "Slinky: a modern framework for modern apps",
      conference: "Scala Days",
      location: "New York",
      url: "https://www.youtube.com/watch?v=8QK00wfQDGg"
    }
  ],
  2017: [
    {
      image: scalabay,
      name: "An Introduction to Scala Native",
      conference: "Scala BAY",
      location: "Santa Clara"
    },
    {
      image: sd2017,
      name: "Stream Away with Scala!",
      conference: "Scala Days",
      location: "Chicago",
      url: "https://www.youtube.com/watch?v=l8cR60eXEPo"
    }
  ],
  2016: [
    {
      image: sd2016,
      name: "Scala on the Move!",
      conference: "Scala Days",
      location: "New York",
      url: "https://www.youtube.com/watch?v=cngpLXViFCE"
    },
  ],
  2015: [
    {
      image: sd2015,
      name: "Scala: Power and Versatility",
      conference: "Scala Days",
      location: "San Francisco",
      url: "https://www.youtube.com/watch?v=tbcXoSMsOGg"
    },
    {
      image: javaoneKids,
      name: "Zero to Fractals in 2 Hours",
      conference: "JavaOne Kids Day",
      location: "San Francisco"
    },
    {
      image: svccKids2015,
      name: "Zero to Fractals in 75 Minutes",
      conference: "Silicon Valley Code Camp Kids",
      location: "Bay Area"
    },
    {
      image: sbtb2015,
      name: "Scala: Power and Versatility",
      conference: "Scala By the Bay",
      location: "Oakland",
      url: "https://www.youtube.com/watch?v=aMjyhjJ-pJc"
    }
  ],
  2014: [
    {
      image: oscon,
      name: "Keynote - The Wonders of Programming",
      conference: "OSCON",
      location: "Portland",
      url: "https://www.youtube.com/watch?v=Co8V3VSRvYM"
    },
    {
      image: sd2014,
      name: "Serious Fun with Scala",
      conference: "Scala Days",
      location: "Berlin",
      url: "https://www.youtube.com/watch?v=YuYSvoFY0dM"
    },
    {
      image: svcc2014,
      name: "Zero to Fractals in 75 Minutes",
      conference: "Silicon Valley Code Camp Kids",
      location: "Bay Area"
    }
  ],
  2013: [
    {
      image: sd2013,
      name: "Fun Programming in Scala: Games, Algorithms, and Apps",
      conference: "Scala Days",
      location: "New York",
      url: "https://www.youtube.com/watch?v=eyIbQn-mhsc"
    },
    {
      image: svcc2013,
      name: "Scala is Fun: Apps and Games",
      conference: "Silicon Valley Code Camp Kids",
      location: "Bay Area"
    }
  ],
  2012: [
    {
      image: sd2012,
      name: "Making Games and Solving Puzzles in Scala",
      conference: "Scala Days",
      location: "London",
      url: "https://skillsmatter.com/skillscasts/3267-making-games-and-solving-puzzles-in-scala#video"
    }
  ],
  2011: [
    {
      image: conwaysBase,
      name: "Game of Life using Scala and Processing",
      conference: "Scala BASE",
      location: "Bay Area",
      url: "https://www.youtube.com/watch?v=6RwrT6N43lY"
    }
  ]
}

const playIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="75" viewBox="0 0 24 24" width="75" style={{
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  }}>
    <path d="M8 5v14l11-7z"/>
    <path d="M0 0h24v24H0z" fill="none"/>
  </svg>
);

const YearTitle = styled.h2`
  font-family: 'Playfair Display', serif;
  font-size: 45px;
  @media (max-width: 800px) {
    font-size: 40px;
  }
  margin-top: 20px;
  margin-bottom: 5px;
  color: #828282;
`;

export default class Talks extends React.Component {
  render() {
    return (
      <div>
        <Nav current="talks"/>
        <Content>
          <PageTitle>I love speaking</PageTitle>
          {Object.keys(talks).sort((a, b) => (a < b) ? 1 : -1).map((year) => {
            const groupedTalks = talks[year].reduce((prev, cur, i) => {
              let lastArr = prev[prev.length - 1];

              if (lastArr.length === 2) {
                lastArr = [];
                prev.push(lastArr);
              }

              lastArr.push(<Card
                key={i}
                right={i % 2 !== 0}
                icon={playIcon}
                image={cur.image}
                name={cur.name}
                title={cur.conference + " • " + cur.location}
                description={""} url={cur.url}
              />);

              return prev;
            }, [[]]);

            return (
              <div key={year}>
                <YearTitle>{year}</YearTitle>
                <div style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}>
                  {groupedTalks}
                </div>
              </div>
            );
          })}
        </Content>
      </div>
    )
  }
}
